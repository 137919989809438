import * as React from "react";

const Section = ({ children, highlightBackground, className }) => {
  const backgroundColor = highlightBackground ? "bg-white" : "";
  return (
    <div className={backgroundColor}>
      <div className="max-w-5xl flex flex-row justify-center m-auto">
        <section
          className={`flex sm:flex-col m-auto justify-around mb-8 pt-8 sm:my-8 ${className}`}
        >
          {children}
        </section>
      </div>
    </div>
  );
};

export default Section;
