import * as React from "react";
import HeadlineQuestion from "./headline-question";
import ImageQuestion from "./image-choice-question";
import Section from "./section";
import TextQuestion from "./text-question";

const Question = (props) => {
  const { type, highlightBackground } = props;
  let Comp = TextQuestion;
  if (type === "multiple") Comp = HeadlineQuestion;
  else if (type === "image") Comp = ImageQuestion;
  return (
    <Section highlightBackground={highlightBackground}>
      <Comp {...props} />
    </Section>
  );
};

export default Question;
