import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const FullBleedQuestionOption = ({ children, image, onClick, selected }) => {
  const inactiveClassName = "";
  const activeClassName = "border-button ";
  const stateClassName = selected ? activeClassName : inactiveClassName;
  return (
    <div className="p-4">
      <button
        onClick={onClick}
        className={`text-white hover:bg-white flex flex-row overflow-clip justify-center rounded-lg shadow-md border-8 hover:border-button hover:text-white ${stateClassName}`}
      >
        <p className="float absolute z-10 mt-44 text-xl text-title font-montserrat font-bold">
          {children}
        </p>
        {image && (
          <div style={{ width: "260px", height: "210px" }}>
            <div
              style={{ width: "310px", marginLeft: "-25px", marginTop: "-5px" }}
            >
              <GatsbyImage
                image={image.gatsbyImageData}
                alt="description"
                className="bg-gradient-to-t"
              />
            </div>
          </div>
        )}
      </button>
    </div>
  );
};

export default FullBleedQuestionOption;
