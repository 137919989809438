import Airtable from "airtable";

Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: "key3Kwv03tnjCNft2",
});
const base = Airtable.base("appJG1LEhACMUGis1");

export const createRecord = ({ values }) => {
  return new Promise((resolve, reject) => {
    base("Intake Form").create(
      [
        {
          fields: values,
        },
      ],
      function (err, records) {
        if (err) {
          console.error(err);
          reject();
        }
        records.forEach(function (record) {
          console.log(record.getId());
        });

        resolve("ok");
      }
    );
  });
};
