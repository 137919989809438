import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const QuestionOption = ({
  children,
  image,
  onClick,
  selected,
  fixedHeight,
  width,
}) => {
  const activeClassName = "bg-button hover:bg-slate-100 border-2 border-button";
  const stateClassName = selected ? activeClassName : undefined;
  const style = { width: width || "200px" };
  if (fixedHeight) style.height = "180px";
  return (
    <div className="p-2">
      <button
        onClick={onClick}
        className={`hover:bg-slate-100 text-button drop-shadow-2xl hidden sm:flex text-sm font-montserrat border-2 p-4 max-w-lg rounded-lg ${stateClassName}`}
      >
        <div className="flex flex-row justify-center m-auto">
          {image && (
            <>
              <div style={{ width: "45px", height: "45px" }} className="m-auto">
                <GatsbyImage image={image.gatsbyImageData} alt="description" />
              </div>
            </>
          )}
          <div className="flex flex-col align-middle m-auto ml-2">
            <p style={{ width: "100px" }} className="max-w-sm text-xs">
              {children}
            </p>
          </div>
        </div>
      </button>
      <button
        style={style}
        onClick={onClick}
        className={`hover:bg-slate-100 text-button drop-shadow-2xl bg-bright-white text-xl sm:hidden font-montserrat border-2 p-4 rounded-lg ${stateClassName}`}
      >
        <div className="flex flex-col justify-center m-auto">
          {children}
          {image && (
            <>
              <div style={{ width: "90px", height: "90px" }} className="m-auto">
                <GatsbyImage image={image.gatsbyImageData} alt="description" />
              </div>
            </>
          )}
        </div>
      </button>
    </div>
  );
};

export default QuestionOption;
