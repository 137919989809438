import * as React from "react";
import { useContext, useEffect } from "react";
import Button from "./button";
import { QuestionContext } from "./question-context";
import QuestionOptions from "./question-options";

const ImageQuestion = ({
  options,
  questionText,
  callToAction,
  isMultiSelect,
  isFullBleed,
  forwardState = false,
  airtableFieldName,
}) => {
  const { registerQuestion, fetchValue } = useContext(QuestionContext);
  useEffect(() => {
    registerQuestion({ id: airtableFieldName, type: "image", isMultiSelect });
  }, [registerQuestion, airtableFieldName, isMultiSelect]);

  let question;
  if (forwardState) {
    question = (fetchValue(airtableFieldName) || []).reduce(
      (acc, cur) => {
        acc[cur] = true;
        return acc;
      },
      { id: airtableFieldName }
    );
  }
  return (
    <div className="m-2 px-16 sm:px-8 flex flex-col justify-center font-vollkorn">
      <p className="text-4xl sm:text-3xl text-headline text-center mb-8">
        {questionText}
      </p>
      <QuestionOptions
        options={options}
        isMultiSelect={isMultiSelect}
        isFullBleed={isFullBleed}
        id={airtableFieldName}
        fixedHeight={true}
      />
      {callToAction === null ? null : (
        <div className="flex flex-row justify-center mx-auto mt-8">
          <Button
            id="main_page_image_choice"
            qs={
              forwardState
                ? { question }
                : // ? qs.stringify(
                  //     { question, utm_campaign: campaign },
                  //     { encode: false }
                  //   )
                  undefined
            }
          >
            {callToAction}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ImageQuestion;
