import * as React from "react";
import { useContext, useEffect } from "react";
import { QuestionContext } from "./question-context";

const TextField = ({ placeholderText, id }) => {
  const { fetchValue, updateValue } = useContext(QuestionContext);
  const handleChange = ({ currentTarget: { value } }) => {
    updateValue({ id, value, type: "text" });
  };
  return (
    <input
      className="p-4 font-montserrat rounded-md border-2 mb-4"
      type="text"
      placeholder={placeholderText}
      value={fetchValue[id]}
      onChange={handleChange}
    />
  );
};

const TextQuestion = ({ questionText, textFields }) => {
  const textFieldComps = textFields.map(
    ({ placeholderText, airtableFieldName }, i) => {
      return (
        <TextField placeholderText={placeholderText} id={airtableFieldName} />
      );
    }
  );
  useEffect(() => {}, []);
  return (
    <div className="m-2 px-16 flex flex-col justify-center font-vollkorn">
      <p className="text-4xl text-headline text-center mb-8">{questionText}</p>
      {textFieldComps}
    </div>
  );
};

export default TextQuestion;
