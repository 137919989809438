import * as React from "react";
import { useState } from "react";
import { useContext } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { QuestionContext } from "../components/question-context";
import { createRecord } from "../airtable-client";
import Question from "../components/question";
import fetchPageDetails from "../fetch-page-details";
import { IS_BROWSER } from "../constants";
import Link from "../components/link";
import { QuestionContextContainer } from "../components/question-context";

const isBrowser = typeof window !== "undefined";

const postHeight = () => {
  if (isBrowser) {
    const el = document.getElementById("gatsby-focus-wrapper");
    if (el !== null) {
      const { height } = el.getBoundingClientRect();
      window.parent.postMessage(["setHeight", height], "*");
    }
  }
};

postHeight();

const SubmitButton = ({ handleSubmit }) => {
  const { fetchState } = useContext(QuestionContext);
  const handleClick = async () => {
    const status = await createRecord(fetchState());
    if (status === "ok") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (IS_BROWSER) {
        window.dataLayer?.push({
          event: "conversion",
        });
      }
      handleSubmit(true);
    }
  };
  return (
    <button
      id="submit_intake_form"
      onClick={handleClick}
      className="font-montserrat bg-button p-4 rounded-lg text-white"
    >
      Submit
    </button>
  );
};

const InnerPage = ({
  multipleChoices,
  imageChoices,
  textQuestions,
  headline,
  successDetail,
  successTitle,
  testimonials,
}) => {
  const [submitted, setSubmitted] = useState(false);
  imageChoices = imageChoices.map((ic) => {
    ic.type = "image";
    ic.callToAction = null;
    return ic;
  });
  multipleChoices = multipleChoices.map((mc) => {
    mc.type = "multiple";
    mc.callToAction = null;
    return mc;
  });
  textQuestions = textQuestions.map((tq) => {
    tq.type = "text";
    return tq;
  });
  const unsortedQuestions = [
    ...imageChoices,
    ...multipleChoices,
    ...textQuestions,
  ];

  const questions = unsortedQuestions
    .sort((a, b) => a.order - b.order)
    .map((question, i) => {
      const highlightBackground = i === 0 || i === unsortedQuestions.length - 1;
      return (
        <Question {...question} highlightBackground={highlightBackground} />
      );
    });

  return (
    <>
      <Helmet>
        <title>Maplecare - Get Started</title>
      </Helmet>
      <div className="bg-white">
        {submitted ? (
          <main className="max-w-5xl m-auto py-20">
            <p className="text-5xl font-vollkorn">{successTitle}</p>
          </main>
        ) : (
          <main className="max-w-4xl m-auto py-20">
            <p className="text-center sm:text-left text-5xl font-vollkorn sm:ml-8 sm:mr-4">
              {headline}
            </p>
          </main>
        )}
      </div>
      <main className="m-auto bg-main-background">
        {submitted ? (
          <div className="flex flex-row mb-32 max-w-5xl m-auto">
            <p className="font-montserrat mt-32 text-lg">{successDetail}</p>
          </div>
        ) : (
          <>
            {questions}
            <div className="flex flex-row justify-center mb-16 bg-white pb-16">
              <button className="font-montserrat border-2 border-button p-4 rounded-lg text-button mr-4">
                <Link to="/">Cancel</Link>
              </button>
              <SubmitButton handleSubmit={setSubmitted} />
            </div>
          </>
        )}
      </main>
    </>
  );
};

const GettingStarted = (props) => {
  const {
    data: {
      allContentfulGettingStartedPage: { nodes },
    },
  } = props;
  const {
    header,
    multipleChoices,
    imageChoices,
    footer,
    textQuestions,
    headline,
    successDetail: { successDetail },
    successTitle,
    testimonials,
  } = fetchPageDetails(nodes);

  return (
    <QuestionContextContainer>
      <InnerPage
        multipleChoices={multipleChoices}
        imageChoices={imageChoices}
        textQuestions={textQuestions}
        headline={headline}
        successDetail={successDetail}
        successTitle={successTitle}
        testimonials={testimonials}
      />
    </QuestionContextContainer>
  );
};

export default GettingStarted;

export const pageQuery = graphql`
  query GettingStartedPageQuery {
    allContentfulGettingStartedPage {
      nodes {
        successTitle
        successDetail {
          successDetail
        }
        campaign
        project
        footer {
          callToAction
          navigationLinks {
            displayValue
            slug
          }
          image {
            gatsbyImageData(placeholder: BLURRED)
            url
          }
        }
        header {
          navigationLinks {
            displayValue
            slug
          }
          logo {
            gatsbyImageData(placeholder: BLURRED)
            url
          }
        }
        multipleChoices {
          airtableFieldName
          order
          isMultiSelect
          questionText
          id
          options {
            optionText
          }
        }
        imageChoices {
          airtableFieldName
          isFullBleed
          order
          isMultiSelect
          questionText
          id
          options {
            optionText
            image {
              gatsbyImageData(placeholder: BLURRED)
              url
            }
          }
        }
        optIn {
          text
        }
        textQuestions {
          id
          order
          questionText
          textFields {
            airtableFieldName
            placeholderText
          }
        }
        headline
        testimonials {
          title
          testimonials {
            name
            location
            quote {
              quote
            }
            status
            headshot {
              gatsbyImageData(placeholder: BLURRED)
              url
            }
          }
        }
      }
    }
  }
`;
