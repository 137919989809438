import * as React from "react";
import { useContext } from "react";
import { QuestionContext } from "./question-context";
import QuestionOption from "./question-option";
import FullBleedQuestionOption from "./full-bleed-question-option";

const QuestionOptions = ({
  options,
  isMultiSelect,
  id,
  isFullBleed = false,
  width,
  fixedHeight,
}) => {
  const { updateValue, fetchValue } = useContext(QuestionContext);
  const value = fetchValue(id);
  const handleClick = (optionText) => () => {
    updateValue({ id, value: optionText, isMultiSelect });
  };
  return (
    <div className="flex flex-row max-w-5xl flex-wrap justify-center">
      {options.map(({ optionText, image }, i) => {
        return isFullBleed ? (
          <FullBleedQuestionOption
            image={image}
            key={i}
            selected={value && value.find((v) => v === optionText)}
            onClick={handleClick(optionText)}
          >
            {optionText}
          </FullBleedQuestionOption>
        ) : (
          <QuestionOption
            image={image}
            key={i}
            selected={value && value.find((v) => v === optionText)}
            onClick={handleClick(optionText)}
            width={width}
            fixedHeight={fixedHeight}
          >
            {optionText}
          </QuestionOption>
        );
      })}
    </div>
  );
};

export default QuestionOptions;
