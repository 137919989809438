import qs from "qs";
import { PROJECT, IS_BROWSER } from "./constants";
const fetchPageDetails = (nodes) => {
  let campaign = "DEFAULT";
  if (IS_BROWSER) {
    campaign =
      qs.parse(window.location.search.slice(1))?.utm_campaign || "DEFAULT";
  }
  return doFetch(nodes, campaign);
};

const doFetch = (nodes, campaign) => {
  const pageDetails = nodes
    .filter(({ project }) => project === PROJECT)
    .find((node) => {
      console.log(campaign === node.campaign, campaign, node.campaign);
      return campaign === node.campaign;
    });
  if (pageDetails !== undefined) return pageDetails;
  else return doFetch(nodes, "DEFAULT");
};

export default fetchPageDetails;
