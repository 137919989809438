import * as React from "react";
import { useContext, useEffect } from "react";
import Button from "./button";
import QuestionOptions from "./question-options";
import { QuestionContext } from "./question-context";

const HeadlineQuestion = ({
  options,
  questionText,
  callToAction,
  forwardState,
  isMultiSelect,
  airtableFieldName,
}) => {
  const { registerQuestion, fetchValue } = useContext(QuestionContext);
  useEffect(() => {
    registerQuestion({ id: airtableFieldName, type: "image", isMultiSelect });
  }, [registerQuestion, airtableFieldName, isMultiSelect]);

  let question;
  if (forwardState) {
    question = fetchValue(airtableFieldName).reduce(
      (acc, cur) => {
        acc[cur] = true;
        return acc;
      },
      { id: airtableFieldName }
    );
  }
  return (
    <div className="m-2 px-16 flex flex-col justify-center font-vollkorn">
      <p className="text-4xl text-headline text-center mb-8">{questionText}</p>
      <QuestionOptions
        id={airtableFieldName}
        isMultiSelect={isMultiSelect}
        options={options}
        width={"800px"}
      />
      {callToAction === null ? null : (
        <Button qs={{ question }}>{callToAction}</Button>
      )}
    </div>
  );
};

export default HeadlineQuestion;
